// GTM Tracking
import { useState, useRef, useEffect, useCallback } from "react";
import debounce from "lodash.debounce";
import { formatNumber } from "@danishagro/shared/src/helpers/formatNumber.helper";

const useProductImpressionTracker = (items) => {
    // References to DOM elements for products
    const productRefs = useRef([]);

    // State to track products currently in the viewport
    const [productsInView, setProductsInView] = useState([]);

    // States to handle user's scrolling behavior
    const [hasScrolled, setHasScrolled] = useState(false);
    const [isCurrentlyScrolling, setIsCurrentlyScrolling] = useState(false);

    const formatPrice = useCallback(
        (price: number) => formatNumber(price, "en-GB", { decimals: 2 }),
        []
    );

    // Function to track and send product impressions to dataLayer
    const trackImpression = debounce(() => {
        if (productsInView.length > 0 && !isCurrentlyScrolling) {
            // Adding an index to each product for clarity
            // TODO: We need more attributes on product, from BE, to mimic Product impressions from S360
            const indexedProducts = productsInView.map((product, index) => ({
                ...product,
                index: index,
                currency: "DKK",
                discount: 0.0,
                item_list_name: "",
                quantity: 1,
            }));

            // Pushing products' data to dataLayer
            window.dataLayer = window.dataLayer || [];
            window.dataLayer?.push({
                event: "view_item_list",
                ecommerce: {
                    items: indexedProducts,
                },
            });

            // Reset productsInView state after sending data
            setProductsInView([]);
        }
    }, 2000);

    useEffect(() => {
        const currentRefs = productRefs.current;

        // Observer to watch when products enter or leave the viewport
        const observer = new IntersectionObserver(
            (entries) => {
                entries?.forEach((entry) => {
                    if (entry?.isIntersecting) {
                        // Extract data from the intersecting DOM element
                        // Extracting the first ID from item_id
                        const firstItemId = entry?.target.getAttribute("data-id")?.split(",")[0];

                        const formatPriceAsNumber = (
                            price: string | undefined | null
                        ): number | string => {
                            // Check for invalid or zero price, return empty string in those cases
                            if (!price || parseFloat(price) === 0) {
                                return 0;
                            }

                            // Convert valid price strings to a number and ensure it has 2 decimal places
                            const parsedPrice = parseFloat(price); // Remove any commas and parse
                            return isNaN(parsedPrice) ? "" : parseFloat(parsedPrice.toFixed(2)); // Ensure 2 decimals or return empty if NaN
                        };

                        const productData = {
                            item_id: firstItemId,
                            item_name: entry?.target.getAttribute("data-name"),
                            item_list_id: entry?.target.getAttribute("data-listid") || "",
                            price: formatPriceAsNumber(
                                entry?.target.getAttribute("data-price") || ""
                            ),
                            item_category: entry?.target.getAttribute("data-category"),
                            item_brand: entry?.target.getAttribute("data-brand"),
                        };

                        // Only add unique products (based on ID and name) to the state
                        setProductsInView((prev) => {
                            if (
                                !prev.find(
                                    (p) =>
                                        p.item_id === productData?.item_id &&
                                        p.item_name === productData?.item_name &&
                                        p.item_list_id === productData?.item_list_id &&
                                        p.price === productData?.price &&
                                        p.item_category === productData?.item_category &&
                                        p.item_brand === productData?.item_brand
                                )
                            ) {
                                return [...prev, productData];
                            }
                            return prev;
                        });
                    }
                });
            },
            { threshold: 0.5 }
        );

        // Start observing all referenced product elements
        currentRefs.forEach((productElement) => {
            if (productElement) observer.observe(productElement);
        });

        // Cleanup: stop observing all elements when the component is unmounted
        return () => {
            observer.disconnect();
        };
    }, [items, formatPrice]);

    useEffect(() => {
        // If there are products in view and user is not currently scrolling, trigger the tracking function
        if (hasScrolled && !isCurrentlyScrolling) {
            trackImpression();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productsInView, isCurrentlyScrolling]);

    useEffect(() => {
        // Handlers to detect start and end of scroll events
        const handleScrollStart = () => {
            setIsCurrentlyScrolling(true);
            if (!hasScrolled) setHasScrolled(true);
        };

        const handleScrollEnd = debounce(() => {
            setIsCurrentlyScrolling(false);
        }, 150);

        // Attach scroll event listeners
        window.addEventListener("scroll", handleScrollStart);
        window.addEventListener("scroll", handleScrollEnd);

        // Cleanup: remove scroll event listeners when the component is unmounted
        return () => {
            window.removeEventListener("scroll", handleScrollStart);
            window.removeEventListener("scroll", handleScrollEnd);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Return the product references so they can be attached to the product elements
    return productRefs;
};

export default useProductImpressionTracker;
