import React, { useRef, useState } from "react";
import { animated, useSpring } from "react-spring";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_Icon, DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { DA_Checkbox } from "@danishagro/shared/src/components/atoms/Checkbox/Checkbox.component";
import { StringProductDataValueFacetResult } from "@relewise/client";
import { useMeasure } from "react-use";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import S from "./ChecklistFacet.module.scss";

interface ChecklistFacetProps {
    expanded?: boolean;
    facet: StringProductDataValueFacetResult;
    selected?: string[];
    setFacet(type: string, value: string): void;
}

export const ChecklistFacet = ({ facet, setFacet, selected, ...props }: ChecklistFacetProps) => {
    const [expanded, setExpanded] = useState(!!props.expanded);
    const id = useRef(`facet-group-${facet.key}`).current;
    const animationEnabled = useRef(!expanded);
    const { getDictionaryString } = useTranslations();
    const [contentRef, { height }] = useMeasure<HTMLDivElement>();
    const contentHeight = useSpring({
        config: { friction: 24 },
        height: `${expanded ? height : 0}px`,
        immediate: !animationEnabled.current,
        onRest: () => (animationEnabled.current = true),
    });

    const sortedOptions = () => {
        if (!facet.available?.length) return [];

        return [...facet.available].sort((a, b) => a.value?.localeCompare(b.value ?? "") ?? 0);
    };

    return (
        <div className={cn(S.container, S.facetWrapper)}>
            <div className={S.facetHeader}>
                <button
                    aria-expanded={expanded}
                    aria-controls={id}
                    onClick={() => setExpanded(!expanded)}
                >
                    {getDictionaryString(`facet.${facet.key}`)}
                    <DA_Icon
                        name={DA_IconNames.ChevronSmallDown}
                        className={cn(S.arrow, expanded && S.rotated)}
                    />
                </button>
                {!expanded ? (
                    <div className={S.helperText}>
                        {selected?.length ? selected.join(",") : getDictionaryString("All")}
                    </div>
                ) : null}
            </div>
            <animated.div
                id={id}
                aria-hidden={!expanded}
                className={S.facetOptions}
                style={contentHeight}
            >
                <div ref={contentRef}>
                    <ul>
                        {sortedOptions().map((option, optionIndex) => (
                            <li key={`option-${optionIndex}`}>
                                <DA_Checkbox
                                    key={optionIndex}
                                    checked={selected?.includes(option.value)}
                                    className={S.optionCheckbox}
                                    name={facet.key?.toLowerCase()}
                                    label={`${option.value} (${option.hits})`}
                                    value={option.value}
                                    onChange={() => setFacet(facet.key, option.value)}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            </animated.div>
        </div>
    );
};
