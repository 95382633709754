// GTM Tracking
import { useCallback } from "react";

type ProductTrackerProps = {
    name: string;
    price: string;
    productNumber: string;
    currency: string;
    eventName?: string;
    category?: string;
    brand?: string;
    listId?: string;
    listName?: string;
    quantity?: number;
};

type DataLayerItem = {
    item_id: string;
    item_name: string;
    price?: number | string;
    quantity: number;
    discount: number;
    currency: string;
    item_category?: string;
    item_brand?: string;
    item_list_id?: string;
    item_list_name?: string;
    // Add more fields here as needed...
};

export const useProductTracker = ({
    name,
    price,
    productNumber,
    currency,
    category,
    brand,
    listId,
    listName,
    quantity,
}: ProductTrackerProps) => {
    // This function will track a product

    const eventsThatRequireQuantity = new Set(["add_to_cart", "remove_from_cart"]);

    // const priceWithTwoDecimals = parseFloat(price.toFixed(2));

    const formatPriceAsNumber = (price: string): number => {
        // Convert the price from string to number with 2 decimal places
        const parsedPrice = parseFloat(price); // Remove commas if any
        return isNaN(parsedPrice) ? 0 : parseFloat(parsedPrice.toFixed(2)); // Ensure 2 decimal places
    };

    const trackProduct = useCallback(
        (eventName: string) => {
            window.dataLayer = window.dataLayer || [];
            // Clear the previous ecommerce object
            window.dataLayer?.push({ ecommerce: null });

            // Extracting the first ID
            const firstProductId = productNumber?.split(",")[0];

            const item: DataLayerItem = {
                item_id: firstProductId,
                item_name: name,
                price: formatPriceAsNumber(price),
                quantity: eventsThatRequireQuantity.has(eventName) && quantity ? quantity : 1,
                discount: 0.0,
                currency: currency || "DKK",
                item_category: category || "",
                item_brand: brand || "",
                item_list_id: listId || "",
                item_list_name: listName || "",
                // Add more fields here as needed...
            };

            // If the current event does not require quantity, then remove the quantity key from the object
            if (!eventsThatRequireQuantity.has(eventName)) {
                delete item.quantity;
            }

            window.dataLayer?.push({
                event: eventName,
                ecommerce: {
                    items: [item],
                },
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [productNumber, name, price, currency, category, brand, listId, listName, quantity]
    );

    return trackProduct;
};
