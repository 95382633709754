import React, { useEffect, useRef, useState } from "react";
import {
    DA_Title,
    DA_TitleTag,
} from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperClass, SwiperOptions } from "swiper/types";
import { DA_ArrowButton, DA_arrowDirection } from "@molecules/ArrowButton/ArrowButton.component";
import { DA_Container } from "@danishagro/shared/src/components/atoms/Container/Container.component";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import useProductImpressionTracker from "@danishagro/shared/src/gtm/useProductImpressionTracker";
import { DA_NewProductSimple } from "@danishagro/shared/src/interfaces/productv2.interface";
import { ProductListItem } from "@templates/Relewise/components/ProductListItem.component";
import { useProductPrices } from "@danishagro/shared/src/hooks/useProductPrices.hook";
import { DA_VetMedicationImageLink } from "@danishagro/shared/src/components/molecules/VetMedication/VetMedication.component";
import S from "./ProductItemSlider.module.scss";

export interface DA_ProductItemSliderProps {
    title: string;
    items: DA_NewProductSimple[];
}

interface VetMedicationObject {
    labelUrl: string;
    siteUrl: string;
}

export const DA_ProductItemSlider = ({ title, items }: DA_ProductItemSliderProps) => {
    const [slidesPerView, setSlidesPerView] = useState(0);
    const [showPreviousButton, setShowPreviousButton] = useState(false);
    const [showNextButton, setShowNextButton] = useState(false);
    const sliderRef = useRef<SwiperClass>(null);
    const [vetMedicationObject, setVetMedicationObject] = useState<VetMedicationObject | null>(
        null
    );
    const [showVetMedication, setShowVetMedication] = useState(false);
    const { getDictionaryString } = useTranslations();

    const priceData = useProductPrices(items, true);

    // GTM Tracking
    const productRefs = useProductImpressionTracker(items);

    const onSlideChange = (swiper: SwiperClass) => {
        setShowPreviousButton(swiper.realIndex > 0);
        setShowNextButton(swiper.realIndex < items.length - slidesPerView);
    };

    const onBreakPointChange = (swiper: SwiperClass, breakpoint: SwiperOptions) => {
        setSlidesPerView(breakpoint.slidesPerView as number);

        // Reset slider when breakpoint changes
        // because Swiper can't figure out when to show/hide buttons
        swiper.slideTo(0);
        setShowPreviousButton(false);
        setShowNextButton(items.length > (breakpoint.slidesPerView as number));
    };

    useEffect(() => {
        const newVetMedicationObject: VetMedicationObject = { labelUrl: "", siteUrl: "" };

        items?.forEach((item) => {
            // Check if item.data is defined before checking for properties within it
            if (item.data && "vetMedicalLink" in item.data && "vetMedicationLogo" in item.data) {
                setShowVetMedication(true);
                newVetMedicationObject.siteUrl = item.data.vetMedicalLink?.value;
                newVetMedicationObject.labelUrl = item.data.vetMedicationLogo?.value;
            }
        });

        setVetMedicationObject(newVetMedicationObject);
    }, [items]);

    return items?.[0] ? (
        <DA_Container noOverflow>
            {showVetMedication && (
                <DA_VetMedicationImageLink
                    link={vetMedicationObject.siteUrl}
                    logo={vetMedicationObject.labelUrl}
                />
            )}
            <div className={S.sliderTopWrapper}>
                <DA_Title h2 tag={DA_TitleTag.DIV}>
                    {title}
                </DA_Title>
            </div>

            <div className={S.productSlider}>
                <div className={S.productSliderInner}>
                    <div className={S.navControl}>
                        {items.length >= slidesPerView && (
                            <>
                                <div
                                    className={cn(
                                        S.navigationWrapper,
                                        S.previous,
                                        showPreviousButton && S.visible
                                    )}
                                >
                                    <DA_ArrowButton
                                        onCLick={() => sliderRef.current.slidePrev()}
                                        iconDirection={DA_arrowDirection.Left}
                                        className={S.buttonNav}
                                        disabled={!showPreviousButton}
                                        title={getDictionaryString("accessibilityButtonPrev")}
                                        small
                                        isGhost={true}
                                    />
                                </div>

                                <div
                                    className={cn(
                                        S.navigationWrapper,
                                        S.next,
                                        showNextButton && S.visible
                                    )}
                                >
                                    <DA_ArrowButton
                                        onCLick={() => sliderRef.current.slideNext()}
                                        className={S.buttonNav}
                                        disabled={!showNextButton}
                                        title={getDictionaryString("accessibilityButtonNext")}
                                        small
                                        isGhost={true}
                                    />
                                </div>
                            </>
                        )}
                    </div>

                    <Swiper
                        onSlideChange={onSlideChange}
                        onReachBeginning={() => setShowPreviousButton(false)}
                        onReachEnd={() => setShowNextButton(false)}
                        onBreakpoint={onBreakPointChange}
                        onSwiper={(swiper) => (sliderRef.current = swiper)}
                        pagination={{
                            type: "progressbar",
                        }}
                        threshold={10}
                        slidesPerView={2}
                        spaceBetween={10}
                        breakpoints={{
                            768: {
                                slidesPerView: 3,
                                spaceBetween: 22,
                            },
                            992: {
                                slidesPerView: 4,
                                spaceBetween: 22,
                            },
                            1200: {
                                slidesPerView: 5,
                                spaceBetween: 22,
                            },
                        }}
                    >
                        {items.map((item, index) => {
                            // Initialize brand as undefined
                            const brand = item.data?.brandListbox?.value || "";

                            return (
                                <SwiperSlide key={index}>
                                    <ProductListItem
                                        ref={(el) => (productRefs.current[index] = el)}
                                        key={`${item.entityId}--${index}`}
                                        brand={brand}
                                        price={priceData[index]}
                                        {...item}
                                        url={item.productUrl}
                                    />
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </div>
            </div>
        </DA_Container>
    ) : null;
};
