import React, { useCallback, useEffect, useState } from "react";
import useProductImpressionTracker from "@danishagro/shared/src/gtm/useProductImpressionTracker";
import { formatNumber } from "@danishagro/shared/src/helpers/formatNumber.helper";
import { DA_NewProductSimple } from "@danishagro/shared/src/interfaces/productv2.interface";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { useProductPrices } from "@danishagro/shared/src/hooks/useProductPrices.hook";
import { DA_ProductPrice } from "@danishagro/shared/src/interfaces/price.interface";
import { ProductListItem } from "./components/ProductListItem.component";
import S from "./Relewise.ProductList.module.scss";

export interface Relewise_ProductListProps {
    products: DA_NewProductSimple[];
    categoryId: string;
}

// type ProductsPriceItem = { productNumber: string };

export const Relewise_ProductList = ({ products, categoryId }: Relewise_ProductListProps) => {
    const { showPrices } = useAppData();
    const [priceData, setPriceData] = useState<DA_ProductPrice[]>([]);
    const prices = useProductPrices(products);

    useEffect(() => {
        if (showPrices && products) {
            setPriceData(prices);
        }
    }, [prices, products, showPrices]);

    // GTM Tracking
    const productRefs = useProductImpressionTracker(products);

    const formatPrice = useCallback(
        (price: number) => formatNumber(price, "en-GB", { decimals: 2 }),
        []
    );

    return (
        <div className={S.container}>
            {products.map((item, index) => {
                // Transforming the category URL
                const category = item?.url?.split("/").slice(0, -1).join("_").replace(/^_/, "");

                // Checking for BrandListbox and assigning value
                // const brand =
                //     item?.specs?.find((spec) => spec?.name === "BrandListbox")?.value || "";

                return (
                    <ProductListItem
                        ref={(el) => (productRefs.current[index] = el)}
                        categoryId={categoryId}
                        data-id={item?.masterProductNumber}
                        data-name={item?.displayName}
                        data-category={category}
                        data-listid={item?.entityId} // TODO: figure out listId
                        data-price={formatPrice(item?.price?.priceWithoutVat)}
                        key={`${item?.entityId}--${index}`}
                        {...item}
                        price={
                            showPrices && priceData[index]?.productNumber === item.productNumber
                                ? priceData[index]
                                : undefined
                        }
                    />
                );
            })}
        </div>
    );
};
