import { useState } from "react";
import { useTranslations } from "../../../../contexts/translations/translations.context";

export const useValidation = (
    addressName: string,
    streetName: string,
    streetNumber: string,
    zipCode: string,
    city: string
) => {
    const { getDictionaryString } = useTranslations();

    const [validationErrorMessages, setValidationErrorMessages] = useState<
        { key: string; value: string }[]
    >([]);

    const validate = () => {
        const tmpValidationErrorMessages = [];

        // Address Description: Mandatory
        if (!addressName || addressName?.length === 0) {
            tmpValidationErrorMessages.push({
                key: "addressName",
                value: getDictionaryString("addressModalValidationAddressNameRequired"),
            });
        }

        // Street Name: Mandatory
        if (!streetName || streetName?.length === 0) {
            tmpValidationErrorMessages.push({
                key: "streetName",
                value: getDictionaryString("AddressModalValidationStreetNameRequired"),
            });
        }

        // Street Number: Mandatory
        if (!streetNumber || streetNumber?.length === 0) {
            tmpValidationErrorMessages.push({
                key: "streetNumber",
                value: getDictionaryString("AddressModalValidationStreetNumberRequired"),
            });
        }

        // Zip Code: Mandatory
        if (!zipCode || zipCode?.length === 0) {
            tmpValidationErrorMessages.push({
                key: "zipCode",
                value: getDictionaryString("AddressModalValidationZipCodeRequired"),
            });
        }

        // Zip Code: Validation
        if (!/^(?:[1-24-9]\d{3}|3[0-8]\d{2})$/.test(zipCode)) {
            tmpValidationErrorMessages.push({
                key: "zipCode",
                value: getDictionaryString("AddressModalValidationZipCodeValidation"),
            });
        }

        // City: Mandatory
        if (!city || city?.length === 0) {
            tmpValidationErrorMessages.push({
                key: "city",
                value: getDictionaryString("AddressModalValidationCityRequired"),
            });
        }

        setValidationErrorMessages(tmpValidationErrorMessages);

        return tmpValidationErrorMessages.length === 0;
    };

    return {
        validationErrorMessages,
        validate,
    };
};
